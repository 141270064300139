import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Col, Row } from "react-bootstrap"
import { graphql, useStaticQuery } from 'gatsby'
import React from "react"
import { Link } from "gatsby"
import Img from 'gatsby-image'
import {FaPhoneAlt} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'

const Header = () => {

  const data = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logo4.png" }) {
          childImageSharp{
            fixed(width: 250, quality: 100){
              ...GatsbyImageSharpFixed
            }
          }
          
        }
      }
    `
  )

  const logo = data.logo.childImageSharp.fixed;
    
  return(

  
  <header>
      <Navbar bg="white" expand="lg">      
    <Navbar.Brand href="/"><Img
        fixed={logo}
        
        className="d-inline-block align-top"
        alt="Bright and Clean Logo"/>
      </Navbar.Brand>
    
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">     
      <Nav className="m-auto">
      {/* <NavDropdown title="Services" id="basic-nav-dropdown"> */}
      <Link className="nav-link" to="/residential-cleaning-services">Residential</Link>
        <Link className="nav-link" to="/commercial-cleaning-services">Commercial</Link>
        
      {/* </NavDropdown> */}
        
        {/* <Link className="nav-link" to="/covid-19">Covid-19</Link> */}
        <Link className="nav-link" to="/contact-us">Contact</Link>
        {/* <Link className="nav-link" to="/about-us">About</Link>         */}
      </Nav>      
      <Button className="header-cta" variant="primary" href="tel:+447802221006"><FaPhoneAlt/></Button> 
      <Button className="header-cta" variant="outline-primary" href="mailto:hello@brightcleansussex.com"><HiOutlineMail/></Button>
      
      
    </Navbar.Collapse>
  </Navbar>
  </header>
   
   )
}



export default Header
