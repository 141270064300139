import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const Footer = () => (
  <footer className="bg-light">
      <Container>
          <Row className="text-center" style={{paddingTop: '2rem'}}>
              <Col xs={12} md={6}>
              <h4 >Our Services</h4>
              <hr/>
                <ul>
                    <li><Link to="/residential-cleaning-services">Residential</Link></li>
                    <li><Link to="/commercial-cleaning-services">Commercial</Link></li>
                </ul>
              </Col>         
              {/* <Col xs={12} md={4}>
              <h4>Our Policies</h4>
              <hr/>
                <ul>
                    <li><Link to="/privacy">Privacy Policy</Link></li>
                    <li><Link to="/cookies">Cookies</Link></li>
                </ul>
                </Col> */}
          
              <Col xs={12} md={6}>
              <h4>Contact Us</h4>
              <hr/>
                <ul>
                    <a href="mailto:hello@brightcleansussex.com"><li>hello@brightcleansussex.com</li></a>
                    <a href="tel:+447802221006"><li>07802227006</li></a>
                    <li>11 Kingfisher Gardens, Chichester, West Sussex PO20 2EQ</li>
                </ul>
              </Col>
          </Row>
          <Row className="text-center">
            <Col><p className="text-muted">Website Design by <a className="text-muted" href="https://www.rjm.digital">RJM Digital</a></p></Col>
          </Row>
      </Container>
  </footer>
   
  
)



export default Footer
